<template>
  <div id="page-user-list">
    
      <form method="POST" enctype="multipart/form-data"  @submit.prevent="submitForm">
       <div class="vx-row mb-6">

        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <RoomForm></RoomForm>
          
          </div>
        </div>
        <div class="vx-col w-full md:w-1/3">
            <div class="vx-card p-6 mb-6">
              <AdditionalInfo></AdditionalInfo>
            </div>
            <div class="vx-card p-6 mb-6">
              <EditRoomImage></EditRoomImage>
            </div>
        </div>
       </div>

            <!-- Save & Reset Button -->
        <div class="vx-card p-4 mb-4">
            <SettingInfo></SettingInfo>
 
            <div class="flex flex-wrap items-center justify-end">
              <vs-button button="submit" class="ml-auto" >Save Changes</vs-button>
            </div>
        </div>
      </form> 
	</div>
</template>

<script>
import vSelect from 'vue-select'
import RoomForm from './common/RoomForm';
import SettingInfo from './common/SettingInfo';
import EditRoomImage from './common/EditRoomImage';
import AdditionalInfo from './common/AdditionalInfo';
import {HOMESTAY_ROOM} from '../../../../constant/entity-identifier'
import { mapGetters, mapActions } from 'vuex'
import loaderMixin from '../../../../mixins/loaderMixin';

export default {
  components: {
    vSelect,
    RoomForm,
    SettingInfo,
    AdditionalInfo,
    EditRoomImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
        roomForm: 'homestay/getRoomFormObj',
        roomFormDropwown: 'homestay/getRoomFormObjDropDown',
    }),
    getHomestayId() {
        return this.$route.params.homestayId;
    },
    getRoomId(){
        return this.$route.params.roomId;
    }
  },
  created() {
      this.openLoading();
     this.fetchAndSetHomeStayRoomById({homestayId:this.getHomestayId, roomId:this.getRoomId}).then((res) => {this.closeLoading()}).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions('homestay', [
        'updateHomeStayRoomAction',
        'fetchAndSetHomeStayRoomById'
    ]),
    submitForm() {
      this.openLoading();
      this.updateHomeStayRoomAction({
            homestayId: this.getHomestayId,
            roomId: this.getRoomId,
            data: this.roomForm
        })
        .then(async response => {
            this.closeLoading();
            this.$vs.notify({ 
                title: 'Homestay Room Updated',
                text: 'Homestay Room Updated successfully.',
                color: 'success',
                position: 'top-right'
            });
            this.$router.push("/homestays/" + this.getHomestayId + "/view");
        }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
