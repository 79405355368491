<template>
  <div class="mb-6">     
       <vs-checkbox 
          class="w-full mt-4" 
          :checked="roomForm.status"
          @change="updateRoomForm({key: 'status', value: $event.target.checked})"> 
          Status 
        </vs-checkbox>
  </div>
</template>


<script>
import vSelect from 'vue-select'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    vSelect
  },
  computed: {
    ...mapGetters({
        roomForm: 'homestay/getRoomFormObj',
        validation: 'validation/getValidationErrors'
    })
  },
  methods: {
    ...mapActions({
      updateRoomForm: 'homestay/updateRoomForm'
    })
  }
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
