<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Homestay Room Details</span>
      </div>
      <div class="vx-col w-full">
        <vs-input 
          class="w-full mt-4" 
          label="Room Name" 
          :danger="validation.name?true:false"
          :danger-text="validation.name?validation.name[0]:''"
          :value="roomForm.name"
          @input="updateRoomForm({key: 'name', value: $event})" />
      </div>
      
      <div class="vx-col w-full md:w-1/3">
         <vs-input 
          class="w-full mt-4" 
          label="Max Adult" 
          :danger="validation.max_adult?true:false"
          :danger-text="validation.max_adult?validation.max_adult[0]:''"
          :value="roomForm.max_adult"
          @input="updateRoomForm({key: 'max_adult', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-1/3">
        <vs-input 
          class="w-full mt-4" 
          label="Max Child" 
          :danger="validation.max_child?true:false"
          :danger-text="validation.max_child?validation.max_child[0]:''"
          :value="roomForm.max_child"
          @input="updateRoomForm({key: 'max_child', value: $event})" />
      </div>

      
      <div class="vx-col w-full md:w-1/3">
        <vs-input 
          class="w-full mt-4" 
          label="Beds" 
          :danger="validation.beds?true:false"
          :danger-text="validation.beds?validation.beds[0]:''"
          :value="roomForm.beds"
          @input="updateRoomForm({key: 'beds', value: $event})" />
      </div>

      <div class="vx-col w-full md:w-3/3">
        <label class=" vs-input--label">Amenities</label>
        <v-select 
          multiple
          :danger="validation.amenities?true:false"
          :value="roomFormDropDown.amenities"
          @input="handleMultipleSelect({key: 'amenities', value: $event})"
          :options="amenities" />
          
        <span class="text-danger text-sm" v-show="validation.amenities?true:false">
          {{ validation.amenities?validation.amenities[0]:''}}
        </span>
      </div>

    </div>
    <div class="w-full mt-4">
      <label for="Description">Description</label>
      <ckeditor 
        :editor="editor"
        :config="editorConfig"
        :value="roomForm.description"
        @input="updateRoomForm({key: 'description', value: $event})"></ckeditor>
      <span class="text-danger text-sm" 
        v-show="validation.description?true:false">
        {{ validation.description?validation.description[0]:''}}
      </span>
    </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {  
  data() {
    return {
      editor: ClassicEditor,
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // toolbar: [ 'bold', 'italic', '|', 'link' ]
      }
    }
  },
  mounted() {
    let params = new URLSearchParams();
    params.append("query[type]", 'room');
    this.fetchAmenities({query: params});
  },
  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        roomForm: 'homestay/getRoomFormObj',
        roomFormDropDown: 'homestay/getRoomFormObjDropDown',
        validation: 'validation/getValidationErrors',
        amenities: 'homestay_amenities/fetchAmenitiesDropdown',
    })
  },
  methods: {
    ...mapActions({
      fetchAmenities: 'homestay_amenities/fetchAndSetAmenitiesDropdown',
      updateRoomForm: 'homestay/updateRoomForm',
    }),
    handleMultipleSelect($event){
      this.updateRoomForm($event);
    }
  }
}
</script>
